import { defineMessages } from 'react-intl';
import { Product } from 'contracts';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  [Product.LiveJasmin]: defineMessages({
    title: {
      id: 'pages.payout.directPayout.studio.seo.livejasmin.title',
      defaultMessage: 'Direct Payout',
    },
    description: {
      id: 'pages.payout.directPayout.studio.seo.livejasmin.description',
      defaultMessage: 'Direct Payout',
    },
  }),
  [Product.Oranum]: defineMessages({
    title: {
      id: 'pages.payout.directPayout.studio.seo.oranum.title',
      defaultMessage: 'Direct Payout',
    },
    description: {
      id: 'pages.payout.directPayout.studio.seo.oranum.description',
      defaultMessage: 'Direct Payout',
    },
  }),
};

export default {
  pages: {
    payout: {
      directPayout: {
        studio: {
          seo,
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'pages.payout.directPayout.studio.navigationMenu.label',
                defaultMessage: 'Direct Payout',
              },
            }),
          },
          ...defineMessages({
            parentTitle: {
              id: 'pages.payout.directPayout.studio.parentTitle',
              defaultMessage: 'PAYOUT',
            },
            title: {
              id: 'pages.payout.directPayout.studio.title',
              defaultMessage: 'Direct Payout',
            },
            subhead: {
              id: 'pages.payout.directPayout.studio.subhead',
              defaultMessage:
                "Direct Payouts allow you to transfer a set percentage of your models' earnings through their chosen payment provider. Enable it for a model by giving them a share percentage.",
            },
          }),
          status: defineMessages({
            active: {
              id: 'pages.payout.directPayout.studio.status.active',
              defaultMessage: 'Active Direct Payout',
            },
            pending: {
              id: 'pages.payout.directPayout.studio.status.pending',
              defaultMessage: 'Pending Direct Payout',
            },
            rejected: {
              id: 'pages.payout.directPayout.studio.status.rejected',
              defaultMessage: 'Rejected Direct Payout',
            },
          }),
          models: {
            ...defineMessages({
              title: {
                id: 'pages.payout.directPayout.studio.models.title',
                defaultMessage: 'Models',
              },
              subhead: {
                id: 'pages.payout.directPayout.studio.models.subhead',
                defaultMessage: 'Activate Direct Payouts for each model by setting a share percentage.',
              },
              searchPlaceholder: {
                id: 'pages.payout.directPayout.studio.models.searchPlaceholder',
                defaultMessage: 'Search by name',
              },
              invalidShareError: {
                id: 'pages.payout.directPayout.studio.models.invalidShareError',
                defaultMessage: 'Share percentage must be in a range between {min} and {max}',
              },
            }),
            notFound: defineMessages({
              title: {
                id: 'pages.payout.directPayout.studio.models.notFound.title',
                defaultMessage: 'No results found',
              },
              info: {
                id: 'pages.payout.directPayout.studio.models.notFound.info',
                defaultMessage: 'Check your search for any typos or try a different search.',
              },
            }),
            noModels: defineMessages({
              title: {
                id: 'pages.payout.directPayout.studio.models.noModels.title',
                defaultMessage: 'No models found',
              },
              info: {
                id: 'pages.payout.directPayout.studio.models.noModels.info',
                defaultMessage: 'Currently, you have no models that are eligible for Direct Payout.',
              },
            }),
          },
        },
      },
    },
  },
};
