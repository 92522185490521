import { switchMap, map, tap, retry, interval, distinctUntilChanged, catchError, of } from 'rxjs';
import type { Observable } from 'rxjs';
import { getDirectPayoutPerformer } from 'services/api/proxy-direct-payout/bff';
import { Store } from 'store';
import user from 'store/user';
// eslint-disable-next-line max-len
import type { DirectPayoutPerformer as APIDirectPayoutPerformer } from 'services/api/proxy-direct-payout/data-contracts';

type DirectPayoutPerformerStore = Omit<APIDirectPayoutPerformer, 'profilePictures'>;

class DirectPayoutPerformer extends Store<DirectPayoutPerformerStore | undefined> {
  source$ = user.onModelChange$.pipe(
    tap(() => {
      super.meta.setLoading(true);
    }),
    switchMap(({ viewTypeId }) =>
      getDirectPayoutPerformer(viewTypeId, {
        headers: {
          'X-Actor-Type': 'performer',
          'X-Actor-Id': viewTypeId,
        },
      }).pipe(
        retry({ count: 3, delay: (err, count) => interval(count * 1000), resetOnSuccess: true }),
        map((response) => response.data.data),
        catchError(() => of(undefined))
      )
    )
  );

  closeOnSignal$ = user.onViewTypeChangeToStudio$;

  constructor() {
    super({ name: 'direct-payout-performer', initialState: undefined });
  }

  get availability$(): Observable<boolean> {
    return this.onChange$.pipe(
      distinctUntilChanged(),
      map((state) => {
        if (!state?.directPayoutStatus || !state?.directPayoutFlowStatus) return false;

        return (
          state?.directPayoutStatus !== 'missing' &&
          state?.directPayoutFlowStatus !== 'created' &&
          state?.directPayoutFlowStatus !== 'rejectedByPerformerPersons'
        );
      })
    );
  }

  setStatus(status: DirectPayoutPerformerStore['directPayoutStatus']): void {
    this.set('directPayoutStatus', status);
  }

  setTechnicalStudioEmail(email: DirectPayoutPerformerStore['technicalStudioEmail']): void {
    this.set('technicalStudioEmail', email);
  }
}

export type { DirectPayoutPerformerStore };
export default new DirectPayoutPerformer();
