import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../payinfo'));

const routes: Route = {
  name: id,
  path: '/:lang?/payout/payinfo',
  element: <AsyncPage />,
};

export default routes;
