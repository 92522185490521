import { defer, of, iif, map } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';
import user from 'store/user';
import performer from 'store/performer';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: () =>
    user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () =>
          iif(
            () => user.isModelView(),
            defer(() => performer.onStatusChange$.pipe(map(() => performer.hasActiveStatus()))),
            of(false)
          )
      )
    ),
};

export default authorization;
