import { iif, of } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import type { RouteAuthorization } from 'contracts';
import user from 'store/user';
import is from 'utils/is';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: user.onChange$.pipe(
    evalAuthorization(
      ({ viewTypeId }) => is.nullish(viewTypeId),
      () => iif(() => user.isModelView(), of(user.isSingleAccountType()), of(!user.hasLimitedAccess()))
    )
  ),
};

export default authorization;
