import type { NavigationMenuItem } from 'contracts';
import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import legacyId from 'pages/redirection/legacy/@@global/id';

import messages from '../payinfo.i18n';

import id from './id';

const menu: NavigationMenuItem[] = [
  {
    name: id,
    parent: legacyId.payout,
    label: messages.pages.payout.payinfo.navigationMenu.label,
    link: () => link.payinfo(),
    weight: 5,
    visibility$: authorization.getAuthorization$('payinfo'),
  },
];

export default menu;
