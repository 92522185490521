import { IconList } from 'components/icon';
import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';

import messages from '../my-accounts.i18n';

import id from './id';

const menu: NavigationMenuItem[] = [
  {
    name: id,
    label: messages.pages.myAccounts.navigationMenu.label,
    icon: { active: IconList.groupTwoSolid, inactive: IconList.groupTwoOutline },
    new: () => true,
    link: () => link[id](),
    weight: 3,
    visibility$: authorization.getAuthorization$(id),
  },
];

export default menu;
