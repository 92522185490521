import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.myAccounts.seo.livejasmin.title',
      defaultMessage: 'My Accounts',
    },
    description: {
      id: 'pages.myAccounts.seo.livejasmin.description',
      defaultMessage: 'My Accounts',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.myAccounts.seo.oranum.title',
      defaultMessage: 'My Accounts',
    },
    description: {
      id: 'pages.myAccounts.seo.oranum.description',
      defaultMessage: 'My Accounts',
    },
  }),
};

export default {
  pages: {
    myAccounts: {
      seo,
      navigationMenu: {
        ...defineMessages({
          label: {
            id: 'pages.myAccounts.navigationMenu.label',
            defaultMessage: 'My Accounts',
          },
        }),
      },
      modal: {
        ...defineMessages({
          title: {
            id: 'pages.myAccounts.modal.title',
            defaultMessage: 'Filters',
          },
          headline: {
            id: 'pages.myAccounts.modal.headline',
            defaultMessage: 'Account Status',
          },
          apply: {
            id: 'pages.myAccounts.modal.apply',
            defaultMessage: 'Apply',
          },
          clear: {
            id: 'pages.myAccounts.modal.clear',
            defaultMessage: 'Clear All',
          },
        }),
      },
      ...defineMessages({
        searchPlaceholder: {
          id: 'pages.myAccounts.searchPlaceholder',
          defaultMessage: 'Search',
        },
        sectionTitle: {
          id: 'pages.myAccounts.sectionTitle',
          defaultMessage: '{product, select, livejasmin {Models} other {Broadcasters}} {total}',
        },
        noResult: {
          id: 'pages.myAccounts.noResult',
          defaultMessage: 'No search result - Please reset your filter settings.',
        },
      }),
    },
  },
};
