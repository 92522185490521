import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.myContent.stories.seo.livejasmin.title',
      defaultMessage: 'Stories',
    },
    description: {
      id: 'pages.myContent.stories.seo.livejasmin.description',
      defaultMessage: 'Stories',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.myContent.stories.seo.oranum.title',
      defaultMessage: 'Stories',
    },
    description: {
      id: 'pages.myContent.stories.seo.oranum.description',
      defaultMessage: 'Stories',
    },
  }),
};

export default {
  pages: {
    myContent: {
      stories: {
        seo,
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.myContent.stories.navigationMenu.label',
              defaultMessage: 'Stories',
            },
          }),
        },
        ...defineMessages({
          moreInfo: {
            id: 'pages.myContent.stories.moreInfo',
            defaultMessage: 'More info',
          },
          subscribers: {
            id: 'pages.myContent.stories.subscribers',
            defaultMessage: 'Subscribers',
          },
        }),
      },
    },
  },
};
