import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';

import messages from '../stories.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  name: id,
  parent: 'myContent',
  label: messages.pages.myContent.stories.navigationMenu.label,
  link: () => link[id](),
  visibility$: authorization.getAuthorization$(id),
  weight: 3,
};

export default menu;
