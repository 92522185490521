import { defineMessages } from 'react-intl';
import { Product } from 'contracts';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  [Product.LiveJasmin]: defineMessages({
    title: {
      id: 'pages.payout.directPayout.model.seo.livejasmin.title',
      defaultMessage: 'Direct Payout',
    },
    description: {
      id: 'pages.payout.directPayout.model.seo.livejasmin.description',
      defaultMessage: 'Direct Payout',
    },
  }),
  [Product.Oranum]: defineMessages({
    title: {
      id: 'pages.payout.directPayout.model.seo.oranum.title',
      defaultMessage: 'Direct Payout',
    },
    description: {
      id: 'pages.payout.directPayout.model.seo.oranum.description',
      defaultMessage: 'Direct Payout',
    },
  }),
};

export default {
  pages: {
    payout: {
      directPayout: {
        model: {
          seo,
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'pages.payout.directPayout.model.navigationMenu.label',
                defaultMessage: 'Direct Payout',
              },
            }),
          },
          ...defineMessages({
            title: {
              id: 'pages.payout.directPayout.model.title',
              defaultMessage: 'Direct Payout',
            },
          }),
          subhead: {
            ...defineMessages({
              active: {
                id: 'pages.payout.directPayout.model.subhead.active',
                defaultMessage:
                  'Direct Payout allows you to receive some share of <b>your earnings</b> through your own payout provider after each closure period.',
              },
              activate: {
                id: 'pages.payout.directPayout.model.subhead.activate',
                defaultMessage:
                  'Direct Payout allows you to receive some share of <b>your earnings</b> ' +
                  'through your own payout provider after each closure period. To enable it, ' +
                  'you need to create a technical account. Please <b>complete the following tasks</b>. ' +
                  'Note that, your Direct Payout will be activated upcoming payout period.',
              },
              consent: {
                id: 'pages.payout.directPayout.model.subhead.consent',
                defaultMessage:
                  'Direct Payout allows you to receive a share of <b>your earnings</b> through your own payout provider after each closure period.',
              },
            }),
          },
          kpi: {
            status: defineMessages({
              title: {
                id: 'pages.payout.directPayout.model.kpi.status.title',
                defaultMessage: 'Direct Payout Status',
              },
              pending: {
                id: 'pages.payout.directPayout.model.kpi.status.pending',
                defaultMessage: 'Pending',
              },
              rejected: {
                id: 'pages.payout.directPayout.model.kpi.status.rejected',
                defaultMessage: 'Rejected',
              },
              active: {
                id: 'pages.payout.directPayout.model.kpi.status.active',
                defaultMessage: 'Active',
              },
            }),
            share: defineMessages({
              title: {
                id: 'pages.payout.directPayout.model.kpi.share.title',
                defaultMessage: 'Direct Payout Share',
              },
            }),
            accountEmail: defineMessages({
              title: {
                id: 'pages.payout.directPayout.model.kpi.accountEmail.title',
                defaultMessage: 'Account Email',
              },
              missing: {
                id: 'pages.payout.directPayout.model.kpi.accountEmail.missing',
                defaultMessage: 'Missing',
              },
              cancel: {
                id: 'pages.payout.directPayout.model.kpi.accountEmail.cancel',
                defaultMessage: 'Cancel',
              },
              updateSuccess: {
                id: 'pages.payout.directPayout.model.kpi.accountEmail.updateSuccess',
                defaultMessage: 'Your account email and password were updated',
              },
              updateFailed: {
                id: 'pages.payout.directPayout.model.kpi.accountEmail.updateFailed',
                defaultMessage: 'An error occurred while updating account email and password. Please try again later.',
              },
              updateFailedDisabled: {
                id: 'pages.payout.directPayout.model.kpi.accountEmail.updateFailedDisabled',
                defaultMessage: 'Direct Payout were disabled by your studio. Please contact them to proceed.',
              },
            }),
          },
          activation: {
            ...defineMessages({
              title: {
                id: 'pages.payout.directPayout.model.activation.title',
                defaultMessage: 'Activate your Direct Payout',
              },
              moreInfo: {
                id: 'pages.payout.directPayout.model.activation.moreInfo',
                defaultMessage: 'More Info',
              },
            }),
            step1: defineMessages({
              name: {
                id: 'pages.payout.directPayout.model.activation.step1.name',
                defaultMessage: 'STEP 01',
              },
              title: {
                id: 'pages.payout.directPayout.model.activation.step1.title',
                defaultMessage: 'Create a new studio account on Model Center',
              },
              list: {
                id: 'pages.payout.directPayout.model.activation.step1.list',
                defaultMessage:
                  '<li>Use a new email address when selecting the Studio/Couple account</li>' +
                  '<li>Complete all the necessary registration steps</li>' +
                  '<li>Your new account must be approved by our Support team before Direct Payouts can be activated. Please note, the approval process may take up to 3 hours.</li>',
              },
            }),
            step2: defineMessages({
              name: {
                id: 'pages.payout.directPayout.model.activation.step2.name',
                defaultMessage: 'STEP 02',
              },
              title: {
                id: 'pages.payout.directPayout.model.activation.step2.title',
                defaultMessage: 'Set your payout method',
              },
              list: {
                id: 'pages.payout.directPayout.model.activation.step2.list',
                defaultMessage:
                  '<li>To receive your earnings, you must register for payments as a Company or Individual</li>' +
                  '<li>Go to Payout > Payout Options and complete your registration.</li>' +
                  '<li>Once your payout application is approved, you can select your preferred payout method at Payout > Payout Options</li>',
              },
            }),
            step3: defineMessages({
              name: {
                id: 'pages.payout.directPayout.model.activation.step3.name',
                defaultMessage: 'STEP 03',
              },
              title: {
                id: 'pages.payout.directPayout.model.activation.step3.title',
                defaultMessage: 'Provide the details of your new account',
              },
            }),
          },
          consent: {
            ...defineMessages({
              title: {
                id: 'pages.payout.directPayout.model.consent.title',
                defaultMessage: 'Model & Studio Services Agreement',
              },
              subhead: {
                id: 'pages.payout.directPayout.model.consent.subhead',
                defaultMessage: 'Approval required',
              },
              hint: {
                id: 'pages.payout.directPayout.model.consent.hint',
                defaultMessage: 'Please read the Services Agreement carefully and accept it by ticking the checkbox.',
              },
              checkboxLabel: {
                id: 'pages.payout.directPayout.model.consent.checkboxLabel',
                defaultMessage:
                  'I, {modelName}, hereby certify that the provided information is accurate and that I agree to be bound by the Services Agreement.',
              },
              confirm: {
                id: 'pages.payout.directPayout.model.consent.confirm',
                defaultMessage: 'I agree',
              },
              decline: {
                id: 'pages.payout.directPayout.model.consent.decline',
                defaultMessage: 'I do not agree',
              },
            }),
          },
          rejected: defineMessages({
            title: {
              id: 'pages.payout.directPayout.model.rejected.title',
              defaultMessage: 'Direct Payout Rejected',
            },
            message: {
              id: 'pages.payout.directPayout.model.rejected.message',
              defaultMessage:
                'You have rejected the Direct Payout agreement. Your Studio will be notified about your Direct Payout status. If you have any questions, please contact your Studio.{br}{br}Since your Direct Payout has been rejected, this page will no longer be visible/accessible to you.',
            },
          }),
        },
      },
    },
  },
};
