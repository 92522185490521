import { combineLatest, defer, iif, map, of } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import application from 'services/application/application';
import user from 'store/user';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';

import id from './id';

const authorization: RouteAuthorization = {
  name: id.payoutOverview,
  authorization$: user.onChange$.pipe(
    evalAuthorization(
      ({ viewTypeId }) => is.nullish(viewTypeId),
      () =>
        iif(
          () => user.isModelView(),
          defer(() => {
            if (application.Oranum) {
              return combineLatest([application.onViewportTypeChange$, of(user.isSingleAccountType())]).pipe(
                map(([viewport, status]) => status && viewport === 'desktop')
              );
            }

            return combineLatest([application.onViewportTypeChange$, of(user.isSingleAccountType())]).pipe(
              map(([viewport, status]) => status && viewport === 'desktop')
            );
          }),
          defer(() =>
            combineLatest([application.onViewportTypeChange$, of(!user.hasLimitedAccess())]).pipe(
              map(([viewport, status]) => status && viewport === 'desktop')
            )
          )
        )
    )
  ),
};

export default authorization;
