import { switchMap, map, tap, retry, interval, distinctUntilChanged, catchError, of, defer } from 'rxjs';
import type { Observable } from 'rxjs';
import { getDirectPayoutAvailability } from 'services/api/proxy-direct-payout/inner';
import { Store } from 'store';
import user from 'store/user';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';

interface DirectPayoutStudioStore {
  isAvailable: boolean;
}

class DirectPayoutStudio extends Store<DirectPayoutStudioStore | undefined> {
  source$ = defer(() => user.onViewTypeChangeToStudio$).pipe(
    tap(() => {
      super.meta.setLoading(true);
    }),
    switchMap(({ viewTypeId }) =>
      getDirectPayoutAvailability({
        headers: {
          'X-Actor-Type': 'studio',
          'X-Actor-Id': viewTypeId!,
        },
      }).pipe(
        retry({ count: 3, delay: (err, count) => interval(count * 1000), resetOnSuccess: true }),
        map(({ data }) => data.data),
        catchError(() => of({ isAvailable: false }))
      )
    )
  );

  closeOnSignal$ = user.onViewTypeChangeToModel$;

  constructor() {
    super({ name: 'direct-payout-studio', initialState: undefined });
  }

  get availability$(): Observable<boolean> {
    return this.onChange$.pipe(
      filterWhileNullish(),
      distinctUntilChanged(),
      map((state) => {
        return Boolean(state?.isAvailable);
      })
    );
  }
}

export type { DirectPayoutStudioStore };
export default new DirectPayoutStudio();
